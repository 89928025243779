/** @jsx jsx */
import { useEffect, useContext } from 'react'
import { jsx, useThemeUI } from 'theme-ui'
import { graphql } from 'gatsby'
import { format } from 'date-fns'
import Img from 'gatsby-image'

import contentParser from '../../plugins/gatsby-wordpress-source-inline-images'

import { PostsViewsContext } from 'src/context/postsViewsContext'
import Layout from 'src/components/layout'
import SEO from 'src/components/seo'
import Tag from 'src/components/tag'
import ClockIcon from 'src/assets/svgs/clock'
import EyeIcon from 'src/assets/svgs/eye'
import PostsList from 'src/components/postsList'
import ShareButtons from 'src/components/shareButtons'
import formatExerpt from 'src/utils/formatExerpt'

const Post = ({
  location,
  data: {
    allWordpressPost: { nodes },
    placeholder,
  },
}) => {
  const { href: postUrl } = location
  const { theme } = useThemeUI()
  const { getPostViews } = useContext(PostsViewsContext)

  const post = nodes[0]

  useEffect(() => {
    setTimeout(() => {
      fetch(
        `${process.env.GATSBY_WORDPRESS_URL}wp-json/api/tbnt/update-post-views/${post.wordpress_id}`
      )
    }, 1500)
  }, [post.wordpress_id])

  return (
    <Layout>
      <SEO
        title={post.title}
        description={formatExerpt(post.excerpt)}
        image={post.featured_media?.localFile?.url}
      />
      <section>
        <div
          sx={{
            position: 'relative',
            overflow: 'hidden',
            flex: 1,
            height: ['380px', '420px', '640px'],
            mb: 6,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
          }}
        >
          <div
            sx={{
              position: 'absolute',
              zIndex: 3,
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              px: 4,
              py: 3,
            }}
          >
            <div sx={{ mb: 4 }}>
              {post.categories.map((tag) => (
                <Tag
                  key={tag.name}
                  name={tag.name}
                  slug={tag.slug}
                  color={tag.description}
                />
              ))}
            </div>

            <h1
              sx={{
                maxWidth: 'contentTitle',
                fontSize: [4, 4, 6],
                color: 'textOnDark',
                textAlign: 'center',
                lineHeight: 'heading',
                mb: 4,
              }}
            >
              {post.title}
            </h1>

            <span
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 2,
                fontSize: 0,
                color: 'textOnDark',
              }}
            >
              {format(new Date(post.date), 'dd.MM.yy')}
              <ClockIcon
                color={theme.colors.textOnDark}
                sx={{ ml: 2, mr: 1 }}
              />
              {Math.round(post.fields.readingTime.minutes / 2)} мин
              <EyeIcon color={theme.colors.textOnDark} sx={{ ml: 2, mr: 1 }} />
              {getPostViews(post.wordpress_id) || post.fields.viewCount}
            </span>
          </div>

          <div
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: 'rgba(0, 0, 0, 0.4)',
              zIndex: 2,
            }}
          />
          {post.featured_media?.localFile?.childImageSharp && (
            <Img
              fluid={post.featured_media.localFile.childImageSharp.fluid}
              sx={{ width: '100%', height: '100%' }}
            />
          )}
          {post.featured_media.localFile &&
            !post.featured_media.localFile.childImageSharp && (
              <div
                sx={{
                  width: '100%',
                  height: '100%',
                  background: `url(${post.featured_media.localFile.publicURL}) center center / cover`,
                }}
              />
            )}
          {!post.featured_media?.localFile && (
            <Img
              fluid={placeholder.childImageSharp.fluid}
              sx={{ width: '100%', height: '100%' }}
            />
          )}
        </div>

        <div sx={{ maxWidth: 'content', mx: 'auto', mb: 9, px: '4' }}>
          {contentParser(
            { content: post.content },
            {
              wordPressUrl: process.env.GATSBY_WORDPRESS_URL,
              uploadsUrl: process.env.GATSBY_WORDPRESS_UPLOADS_URL,
            }
          )}
        </div>
        <footer>
          <div sx={{ maxWidth: 'content', mx: 'auto', mb: [9, '98px'] }}>
            <hr sx={{ mb: 4 }} />
            <h3 sx={{ textAlign: 'center', mb: 4 }}>Поделиться статьей</h3>
            <ShareButtons url={postUrl} />
          </div>
          <div>
            <h3 sx={{ textAlign: 'center', mb: 4 }}>Похожие статьи</h3>
            <PostsList
              isPost
              categoryId={post.categories[0].wordpress_id}
              initialPostsToShow={6}
              containerSx={{ mt: 0 }}
              postId={post.wordpress_id}
            />
          </div>
        </footer>
      </section>
    </Layout>
  )
}

export default Post

export const query = graphql`
  query($id: Int!) {
    allWordpressPost(filter: { wordpress_id: { eq: $id } }) {
      nodes {
        wordpress_id
        slug
        excerpt
        title
        date
        content
        categories {
          wordpress_id
          name
          slug
          description
        }
        fields {
          readingTime {
            minutes
          }
          viewCount
          contentWithoutShortcodes
        }
        featured_media {
          localFile {
            url
            publicURL
            childImageSharp {
              fluid(maxWidth: 1080, quality: 100) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
    placeholder: file(relativePath: { eq: "placeholder.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
